import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
//import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";
import firstCase from "../images/runnermock1.png";
import firstCaseLogo from "../images/runner.svg";
import feraName from "../images/mafer.svg";
import secondCase from "../images/erinmock1.png";
import secondCaseLogo from "../images/erinlogo.svg";
import thirdCase from "../images/aseguradorahome.png";
import thirdCaseLogo from "../images/aseguradoralogo.png";
import mail from "../images/mail.svg";
import linkedin from "../images/inkedin.svg";
//import itzam from "../images/itzam.svg";
//import comercio from "../images/comercio.svg";
//import usfq from "../images/usfq.svg";
import workedat from "../images/workedat.svg";
import downloadFile from "../downloads/fernanda_ramirez_cv.pdf";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section>
        <Link to="/">
          <div className="relative h-auto w-auto ...">
            <div>
              <img
                alt="Case One"
                className="md:w-64 object-cover "
                src={feraName}
              />
              {/*   <h2 className=" inline-block p-0.5 px-4 mb-4 leading-tight text-2xl font-semibold tracking-wide text-gray-800 bg-green-200">
          Fernanda Ramírez
        </h2> */}
            </div>
            <div className="-m-2">
              <h2 className="inline-block p-0 px-4 mt-4 mb-4 md:text-1xl font-light text-gray-800 text-sm">
                Product Designer 
              </h2>
            </div>

          </div>
        </Link>

        <div className=" inline-flex rounded-md shadow  md:mr-8 md:mb-0 mb-2 mt-2 text-center">

          <a href={downloadFile} download className="inline-flex items-center justify-center  md:py-1 py-1 pl-2 pr-6 border border-transparent text-sm font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 ">
            My resume ⤓
                    </a>
        </div>
        <div className=" w-40 relative flex items-center justify-center grid md:grid-cols-2 gap-0 grid-cols-2  py-4">

          <div className="md:flex-shrink-0 md:w-1/2 w-full text-center">
            <a href="mailto:mframirezc.97@gmail.com">
            <img
              alt="Case One"
              className="h-auto w-8 object-cover items-center justify-center ml-1"
              src={mail}
            />
            </a>
          </div>

          <div className="md:flex-shrink-0 md:w-1/2 w-full text-center">
          <a href="https://www.linkedin.com/in/maría-fernanda-ramírez-520258148">
            <img
              alt="Case One"
              className="h-auto w-8 object-cover -ml-8   "
              src={linkedin}
            />
            </a>

          </div>



        </div>

        <hr className="w-5/12 mt-8"></hr>

        <div className="relative flex items-center justify-center grid grid-cols-1 md:pt-10 pt-8  ">
          <div className="tracking-wide text-xl text-black font-extrabold  ">About me</div>
          <p className="block text-left mt-1 text-base  text-gray-900 font-light pt-6 ">Interaction Designer and Interdisciplinary Design and Media PhD Student at Northeastern University [📍Realty Design Studio] </p>
          {/* <span className="block text-left mt-1 text-base  text-gray-900 font-light ">I am a second year <span className="font-semibold"> Digital and Interaction Design </span> <span> MSc student at <span className="bg-green-200">Politecnico di Milano. </span></span></span> */}
          <p className="block text-left mt-1 text-base  text-gray-900 font-semibold py-6 ">I have worked at: </p>
          <div className="md:flex-shrink-0 md:w-1/2 w-full text-center">
            <img
              alt="Case One"
              className="h-auto w-70 object-cover  "
              src={workedat}
            />

          </div>

   

        </div>
        <hr className="w-5/12 mt-8"></hr>

        <div className="relative flex items-center justify-center grid grid-cols-1 pt-10 ">
          <div className="tracking-wide text-xl text-black font-extrabold  ">My work</div>
        </div>

      </section>

      <section className=" md:mt-20 mt-10">

        <Link to="/planerunner/">
          <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
            <div className="md:flex">
              <div className="md:flex-shrink-0 md:w-1/2 w-full">

                <img
                  alt="Case One"
                  className="h-full w-full object-cover "
                  src={firstCase}
                />
              </div>
              <div className="relative p-8">
                <div className="tracking-wide px-2 text-sm font-semibold bg-blue-100 mb-4 text-gray-800 w-40 absolute right-0">Class Project</div>
                <br></br>
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case study</div>
                <div>
                  <img
                    alt="Case One"
                    className="w-32 object-cover py-2"
                    src={firstCaseLogo}
                  />

                </div>

                <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">micro:bit Powered Toy & Game</a>

                <p className="mt-2 text-gray-500">Endless runner game where you control a virtual plane with a physical toy plane.</p>
              </div>
            </div>
          </div>



          {/*  <h2 className="inline-block p-3 mb-4 text-2xl font-bold bg-yellow-400">
          Hey there! Welcome to your first Gatsby site.
        </h2>

        <p className="leading-loose">
          This is a barebones starter for Gatsby styled using{` `}
          <a
            className="font-bold text-gray-900 no-underline"
            href="https://tailwindcss.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tailwind CSS
          </a>
          , a utility-first CSS framework.
        </p> */}
        </Link>
      </section>

      <section className=" md:mt-20 mt-10 hidden md:block ">
        <Link to="/aseguradora/">
          <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
            <div className="md:flex">

              <div className="relative p-8">
                <div className="tracking-wide px-8 text-sm font-semibold bg-yellow-200 mb-4 text-gray-800 w-52 absolute left-0">Freelance</div>
                <br></br>
                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold mt-4">Case study</div>
                <div>
                  <img
                    alt="Case One"
                    className="w-32 object-cover py-2 "
                    src={thirdCaseLogo}
                  />

                </div>

                <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Request Vehicle Assistance and Report Claims</a>

                <p className="mt-2 text-gray-500">This app offers fast solutions to insurance holders 24 hours a day, 365 days a year.</p>
              </div>
              <div className="md:flex-shrink-0 md:w-1/2 w-full">

                <img
                  alt="Case One"
                  className="h-full w-full object-cover "
                  src={thirdCase}
                />
              </div>
            </div>
          </div>

        </Link>




      </section>


      <section className=" md:mt-20 mt-10 md:hidden">
        <Link to="/aseguradora/">
          <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
            <div className="md:flex">
              <div className="md:flex-shrink-0 md:w-1/2 w-full">

                <img
                  alt="Case One"
                  className="h-full w-full object-cover "
                  src={thirdCase}
                />
              </div>
              <div className="relative p-8">
                <div className="tracking-wide text-sm px-2 font-semibold bg-yellow-200 mb-4 text-gray-800 w-56 absolute right-0">Freelance</div>
                <br></br>
                <div className="block uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case study</div>
                <div>
                  <img
                    alt="Case One"
                    className="w-32 object-cover py-2"
                    src={thirdCaseLogo}
                  />

                </div>

                <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Request Vehicle Assistance and Report Claims</a>

                <p className="mt-2 text-gray-500">This app offers fast solutions to insurance holders 24 hours a day, 365 days a year.</p>
              </div>
            </div>
          </div>

        </Link>
      </section>



      <section className=" md:mt-20 mt-10">
        <Link to="/erin/">
          <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
            <div className="md:flex">
              <div className="md:flex-shrink-0 md:w-1/2 w-full">

                <img
                  alt="Case One"
                  className="h-full w-full object-cover "
                  src={secondCase}
                />
              </div>
              <div className="relative p-8">
                <div className="tracking-wide px-2 text-sm font-semibold bg-green-100 mb-4 text-gray-800 w-56 absolute right-0">Undergraduate Thesis</div>
                <br></br>
                <div className="block uppercase tracking-wide text-sm text-indigo-500 font-semibold">Case study</div>
                <div>
                  <img
                    alt="Case One"
                    className="w-16 object-cover py-2"
                    src={secondCaseLogo}
                  />

                </div>

                <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Platformer Game based on Personalities</a>

                <p className="mt-2 text-gray-500">What if considering that video games have become such an important phenomenon worldwide we tackle the stereotypical model of creation with a digital product design approach?</p>
              </div>
            </div>
          </div>

        </Link>
      </section>

      


    </Layout>
  );
}

export default IndexPage;
